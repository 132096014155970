// add route key here first
export enum ROUTE_KEY {
  'LOGIN' = 'LOGIN',
  'DASHBOARD' = 'DASHBOARD',
  'MANAGE_TEAMS' = 'MANAGE_TEAMS',
  'ACTIVITY_LOG' = 'ACTIVITY_LOG',
  'CONTROL_CENTER' = 'CONTROL_CENTER',
  'ANALYTICS' = 'ANALYTICS',
  'DEVICES' = 'DEVICES',
  'SLATES' = 'SLATES',
}

// add route path here then
export const ROUTE_PATH = {
  [ROUTE_KEY.LOGIN]: '/',
  [ROUTE_KEY.DASHBOARD]: '/dashboard',
  [ROUTE_KEY.MANAGE_TEAMS]: '/manage-teams',
  [ROUTE_KEY.ACTIVITY_LOG]: '/activity-log',
  [ROUTE_KEY.CONTROL_CENTER]: '/control-center',
  [ROUTE_KEY.ANALYTICS]: '/analytics',
  [ROUTE_KEY.DEVICES]: '/outdated-devices',
  [ROUTE_KEY.SLATES]: '/slates',
};
