import { PropsWithChildren } from 'react';

import { Box, Tooltip, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';

export const EventElementInputBox = ({
  name,
  dashed = false,
  children,
  showDelete = false,
  onDelete,
  deleteTooltip,
  error = false,
}: PropsWithChildren<{
  name: string;
  onDelete: () => void;
  deleteTooltip: string;
  error?: boolean;
  dashed?: boolean;
  showDelete?: boolean;
}>) => {
  return (
    <Box
      width="100%"
      minWidth="420px"
      p={2.5}
      borderRadius={1}
      border={dashed ? '1px dashed' : '1px solid'}
      borderColor={error ? 'red' : 'highlight.secondary'}
      sx={{
        '&:hover': {
          borderColor: `${error ? 'red' : 'grey.600'}`,
        },
      }}
      position="relative"
      mb={4}
      textAlign="center"
    >
      <Box
        position="absolute"
        top="-15px"
        left="4px"
        bgcolor="white"
        px={1}
        py={0.5}
        fontWeight="bold"
      >
        <Typography variant="body2" color={error ? 'red' : 'inherit'}>
          {name}
        </Typography>
      </Box>
      {showDelete && (
        <Tooltip title={deleteTooltip} placement="top">
          <Box
            position="absolute"
            top="-15px"
            right="4px"
            bgcolor="white"
            color="error.main"
            px={1}
            py={0.5}
            sx={{ cursor: 'pointer' }}
            onClick={onDelete}
          >
            <Delete fontSize="small" />
          </Box>
        </Tooltip>
      )}
      {children}
    </Box>
  );
};
