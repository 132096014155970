import { API } from 'aws-amplify';

import environment from 'config';
import { AWSResource, OutdatedDevicesResponse } from 'types';

export const getSourceResourcesByID = (id: string): Promise<AWSResource[]> => {
  return API.get(environment.API.REST.NAME, `source/${id}/resources`, {});
};

export const getOutputResourcesByID = (id: string): Promise<AWSResource[]> => {
  return API.get(environment.API.REST.NAME, `output/${id}/resources`, {});
};

export const getBroadcasterIp = (
  id: string
): Promise<{
  broadcasterIp: string;
  id: number;
  name: string;
}> => {
  return API.get(environment.API.REST.NAME, `event/${id}/broadcaster`, {});
};

export const getOutdatedMedialiveDevices = (): Promise<OutdatedDevicesResponse[]> => {
  return API.get(environment.API.REST.NAME, `awsResource/outdated-devices`, {});
};
