import { UserRole } from './AuthConstants';

export const DashboardAllowedRoles = [
  UserRole.APPLICATION_ADMIN,
  UserRole.GROUP_MANAGER,
  UserRole.EVENT_OPERATOR,
];

export const ControlCenterAllowedRoles = [
  UserRole.APPLICATION_ADMIN,
  UserRole.GROUP_MANAGER,
  UserRole.EVENT_OPERATOR,
];

export const ManageTeamsAllowedRoles = [UserRole.APPLICATION_ADMIN, UserRole.GROUP_MANAGER];

export const ActivityLogAllowedRoles = [
  UserRole.APPLICATION_ADMIN,
  UserRole.GROUP_MANAGER,
  UserRole.EVENT_OPERATOR,
];

export const SlatesAllowedRoles = [
  UserRole.APPLICATION_ADMIN,
  UserRole.GROUP_MANAGER,
  UserRole.EVENT_OPERATOR,
];

export const AnalyticsAllowedRoles = [UserRole.APPLICATION_ADMIN];

export const AllowedUserRoleCreations: Record<UserRole, UserRole[]> = {
  [UserRole.APPLICATION_ADMIN]: [
    UserRole.APPLICATION_ADMIN,
    UserRole.GROUP_MANAGER,
    UserRole.EVENT_OPERATOR,
    UserRole.UNASSIGNED,
  ],
  [UserRole.GROUP_MANAGER]: [UserRole.GROUP_MANAGER, UserRole.EVENT_OPERATOR, UserRole.UNASSIGNED],
  [UserRole.EVENT_OPERATOR]: [UserRole.EVENT_OPERATOR, UserRole.UNASSIGNED],
  [UserRole.UNASSIGNED]: [],
};

export const CreateEventAllowedRoles = [
  UserRole.EVENT_OPERATOR,
  UserRole.GROUP_MANAGER,
  UserRole.APPLICATION_ADMIN,
];

export const EditEventAllowedRoles = [
  UserRole.EVENT_OPERATOR,
  UserRole.GROUP_MANAGER,
  UserRole.APPLICATION_ADMIN,
];

export const DeleteEventAllowedRoles = [
  UserRole.EVENT_OPERATOR,
  UserRole.GROUP_MANAGER,
  UserRole.APPLICATION_ADMIN,
];

export const FilterByTeamAllowedRoles = [UserRole.APPLICATION_ADMIN];

export const DeviceAllowedRoles = [UserRole.GROUP_MANAGER, UserRole.EVENT_OPERATOR];

export const ManageStreamAllowedRoles = [
  UserRole.EVENT_OPERATOR,
  UserRole.GROUP_MANAGER,
  UserRole.APPLICATION_ADMIN,
];
