import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import _ from 'lodash';

import { Button, Grid, Typography } from '@mui/material';
import { LaunchRounded } from '@mui/icons-material';

import { PopUp } from 'components/PopUp';

import { PopUpButtonProps } from 'types';
import { buildStyles } from 'utils/helpers';

interface IJSONGridDetail {
  data: Record<string, any>;
}

const styles = buildStyles({
  gridItemContainer: (theme) => ({
    [theme.breakpoints.between('sm', 'md')]: {
      width: '50%',
    },
  }),
  rawPopup: {
    padding: '10px',
    backgroundColor: '#F2F2F2',
    textAlign: 'left',
    fontSize: '12px',
    borderRadius: '4px',
  },
});

const restrictHeaderKeys = ['details', 'updatedAt', 'type', 'Public URL'];

export const DefaultGridItem = ({ itemData, itemKey }: { itemData: any; itemKey: string }) => {
  return (
    <Grid item container md={6} key={itemKey} direction="column" sx={styles.gridItemContainer}>
      <Grid item>
        <Typography color="grey.600">
          <b>{itemKey.charAt(0).toUpperCase() + itemKey.slice(1)}</b>
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{
            overflow: 'hidden',
            wordBreak: 'break-all',
            textOverflow: 'ellipsis',
          }}
        >
          {itemData || '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const JSONGridDetail = (props: IJSONGridDetail) => {
  const [keys, setKeys] = useState([]);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popupData, setPopupData] = useState({});

  const renderGridItem = (itemData, itemKey) => {
    const displayItemKey = itemKey?.replace(/_/g, ' ');

    return <DefaultGridItem itemData={itemData} itemKey={displayItemKey} key={displayItemKey} />;
  };

  const payloadAttr: PopUpButtonProps[] = [
    {
      name: 'CANCEL',
      handler: () => {
        setPopUpOpen(false);
      },
      variant: 'outlined',
    },
  ];

  const handlePopupOpen = (popupContent: any, type: 'string' | 'json') => {
    if (type === 'json') {
      setPopupData(JSON.stringify(popupContent, null, 2));
    } else {
      setPopupData(popupContent);
    }
    setPopUpOpen(true);
  };

  useEffect(() => {
    if (Array.isArray(props.data)) {
      setKeys(Object.keys(props.data[0]).filter((key) => !restrictHeaderKeys.includes(key)));
    } else {
      setKeys(Object.keys(props.data).filter((key) => !restrictHeaderKeys.includes(key)));
    }
  }, [props.data]);

  return (
    <Grid container direction="row" wrap="wrap" spacing={2}>
      {keys?.map((key) => {
        return renderGridItem(props.data[key], key);
      })}
      {/* only for ZENMASTER_SOURCE */}
      {props.data['Public URL'] && !_.isEmpty(props.data['Public URL']) && (
        <Grid item container md={6} key="publicUrl" direction="column">
          <Grid item>
            <Typography color="grey.600">
              <b>Public URL</b>
            </Typography>
          </Grid>
          <Grid item>
            <a href={props.data['Public URL']}>{props.data['Public URL']}</a>
          </Grid>
        </Grid>
      )}
      {props.data?.details && !_.isEmpty(props.data?.details) && (
        <Grid item container md={6} key="details" direction="column">
          <Grid item>
            <Typography color="grey.600">
              <b>Details</b>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              size="small"
              sx={{ px: 0 }}
              onClick={() =>
                handlePopupOpen(
                  props.data?.details,
                  typeof props.data?.details === 'string' ? 'string' : 'json'
                )
              }
            >
              View Raw &nbsp;
              <LaunchRounded style={{ width: '14px', height: '14px' }} />
            </Button>
          </Grid>
          <PopUp
            isPopUpOpen={popUpOpen}
            buttons={payloadAttr}
            fullWidth
            maxWidth="lg"
            close={() => setPopUpOpen(false)}
          >
            <Typography
              style={{ marginBottom: '30px', fontWeight: 'bold', textAlign: 'center' }}
              variant="h6"
            >
              Raw Metadata Properties
            </Typography>
            {popupData ? (
              Array.isArray(popupData) ? (
                <pre style={styles.rawPopup as CSSProperties}>
                  {popupData?.map((d) => d?.log || '').join('\n') || ''}
                </pre>
              ) : (
                <pre style={styles.rawPopup as CSSProperties}>{popupData as ReactNode}</pre>
              )
            ) : (
              <Typography color="grey.600" style={{ marginTop: '30px' }}>
                No Data
              </Typography>
            )}
          </PopUp>
        </Grid>
      )}
    </Grid>
  );
};
