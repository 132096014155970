import _ from 'lodash';

import { Box } from '@mui/material';

import { JSONGridDetail } from 'components/Event/JSONGridDetail';

import { AWSResourceType, OutputType, SourceType } from 'types';

const transformResourceDetails = (data: any) => {
  const { details, type, specifications, broadcasterData } = data;
  const commonZixiDetails = {
    details,
    Id: details?.id || '-',
    Name: details?.name || '-',
    'General Status': details?.generalStatus || '-',
  };
  switch (type) {
    case AWSResourceType.ZENMASTER_SOURCE:
      return {
        ...commonZixiDetails,
        'Target Host': details?.target_host || '-',
        Port:
          details?.listening_port ||
          details?.remote_port ||
          (specifications?.sourceType == SourceType.ZIXI && details?.type == 'other' && '2088') ||
          '-',
        Latency: details?.latency || '-',
        'Max Bitrate': details?.max_bitrate || '-',
        'Stream Id': details?.input_id || details?.stream_id || '-',
        ...(specifications?.sourceType == SourceType.ZIXI && {
          'Remote Id': (!_.isEmpty(details) && broadcasterData?.broadcasterName) || '-',
          'Broadcaster Ip': (!_.isEmpty(details) && broadcasterData?.broadcasterIp) || '-',
        }),
      };
    case AWSResourceType.ZENMASTER_CHANNEL:
      return commonZixiDetails;
    case AWSResourceType.ZENMASTER_TARGET:
      return {
        ...commonZixiDetails,
        Latency: details?.latency || '-',
        Port:
          details?.port ||
          details?.remote_port ||
          (!_.isEmpty(details) &&
            (specifications?.type == 'push'
              ? '2088'
              : specifications?.type == 'pull'
              ? '2077'
              : '-')) ||
          '-',
        'Stream Id': details?.stream_id || '-',

        ...(specifications && specifications?.type?.toUpperCase() !== OutputType.SRT
          ? {
              Target: details?.target || '-',
              'Max Bitrate': details?.max_bitrate || '-',
            }
          : {
              Host: details?.host || '-',
            }),
        'Broadcaster Ip': (!_.isEmpty(details) && broadcasterData?.broadcasterIp) || '-',
      };
    default:
      return {};
  }
};

export const ZixiResourceDetails = ({ data, broadcasterData }: any) => {
  const transformedDetails = transformResourceDetails({ ...data, broadcasterData });

  return (
    <Box py={1}>
      <JSONGridDetail data={transformedDetails} />
    </Box>
  );
};
