import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Handle, NodeProps, Position } from 'reactflow';
import ReactHlsPlayer from '@panelist/react-hls-player/dist';
import { useQuery } from '@tanstack/react-query';

import { CircularProgress, IconButton, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { ContentCopy, LibraryAddCheck, MovieRounded } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';

import { Center } from 'components/Common/Centered';
import { DeviceStatus } from 'components/Common/DeviceStatus';
import { PopUp } from 'components/PopUp';
import { ResourceDetails } from 'components/Resource/ResourceDetails';

import * as ResourceService from 'services/ResourceService';
import * as SourceService from 'services/sourceService';

import { GroupInfoContext } from 'contexts/GroupInfoContext';

import { SourceTypeDisplayName } from 'constants/CommonConstants';
import { FeedNodeStatusType } from 'constants/DeviceStates';
import { EventStreamStatus } from 'constants/StreamConstants';
import { UserTourClassSelector } from 'constants/UserTourConstants';

import {
  AWSResourceType,
  DevicePreview,
  FeedGraphSourceNodeData,
  PopUpButtonProps,
  SourceType,
  WebSocketResponse,
  WebSocketResponseByType,
  WebSocketResponseTypes,
} from 'types';
import { GRAPH_NODE_DIMENSIONS } from 'utils/eventHelpers';
import { getStatusColor, imageFromArrayBuffer } from 'utils/helpers';

import { socketConnection } from '../../../contexts/WebSocketContext';

const NodePreviewPlaceholder = (props: { source: FeedGraphSourceNodeData }) => {
  switch (props.source.type) {
    case SourceType.ELEMENTAL_LINK:
      return (
        <Box
          borderRadius="5px"
          width="100%"
          height="100%"
          position="relative"
          sx={{ cursor: 'normal' }}
        >
          <Center
            width="100%"
            height="100%"
            borderRadius={1}
            bgcolor="grey.100"
            color="grey.600"
            position="absolute"
            top="0"
            textAlign="center"
          >
            <MovieRounded fontSize="large" sx={{ mb: 1 }} />
            <Typography variant="body2">Loading...</Typography>
          </Center>
        </Box>
      );
    case SourceType.SLATE_INPUT:
    case SourceType.SRT:
    case SourceType.ZIXI:
    case SourceType.RTMP:
      return (
        <Center width="100%" height="100%" bgcolor="grey.50" borderRadius={1}>
          <Typography variant="h5" color="grey.600" mb={3} textTransform={'uppercase'}>
            <b>
              {SourceTypeDisplayName[props.source.type]}{' '}
              {(props.source.type == SourceType.ZIXI && props.source.specifications?.mode) ||
                (props.source.type == SourceType.SRT && props.source.specifications?.mode) ||
                ''}
            </b>
          </Typography>
        </Center>
      );
    default:
      return <></>;
  }
};

const NodeDetails = (props: { source: FeedGraphSourceNodeData; isFloating: boolean }) => {
  const renderDetails = () => {
    switch (props.source.type) {
      case SourceType.ELEMENTAL_LINK:
      case SourceType.SLATE_INPUT:
      case SourceType.SRT:
      case SourceType.ZIXI:
      case SourceType.RTMP:
        return (
          <DeviceStatus
            status={
              props.source?.status || { type: FeedNodeStatusType.NEGATIVE, value: 'NOT PRESENT' }
            }
          />
        );
      default:
        return <></>;
    }
  };

  const statusColor = getStatusColor(props.source.status?.type);

  return !props.isFloating ? (
    <Stack
      width="calc(100% - 4px)"
      height="50px"
      position="absolute"
      bgcolor="grey.100"
      bottom="2px"
      left="2px"
      zIndex={10}
      justifyContent="center"
      style={{
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px',
      }}
    >
      {renderDetails()}
    </Stack>
  ) : (
    <>
      <Box
        position="absolute"
        top="-24px"
        left="0px"
        bgcolor={statusColor + '20'}
        px={1}
        py={0.1}
        sx={{
          borderTopRightRadius: '4px',
          borderTopLeftRadius: '4px',
        }}
        zIndex={-1}
      >
        <Typography color={statusColor}>
          <b>{SourceTypeDisplayName[props.source.type]}</b>
        </Typography>
      </Box>
      <Box
        position="absolute"
        top="-24px"
        right="0px"
        bgcolor={statusColor + '20'}
        px={1}
        py={0.1}
        sx={{
          borderBottomRightRadius: '4px',
          borderBottomLeftRadius: '4px',
        }}
        zIndex={-1}
      >
        {renderDetails()}
      </Box>
    </>
  );
};

export const SourceFeedNode = ({
  data: source,
  id,
  type,
}: NodeProps<FeedGraphSourceNodeData>): JSX.Element => {
  const [viewPopUpOpen, setViewPopUpOpen] = useState(false);
  const playerRef = useRef<HTMLVideoElement>(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const viewButtonAttr: PopUpButtonProps[] = [
    {
      name: 'OK',
      handler: () => {
        setViewPopUpOpen(false);
      },
      variant: 'contained',
    },
  ];

  const [sourcePreview, setSourcePreview] = useState<{
    type: SourceType;
    preview: DevicePreview;
  }>();
  const statusColor = getStatusColor(source.status?.type);
  const [copied, setCopied] = useState(false);
  const previewSrc = useMemo(() => {
    switch (sourcePreview?.type) {
      case SourceType.ELEMENTAL_LINK: {
        setHasLoaded(true);
        if (sourcePreview?.preview?.Body?.length) {
          sourcePreview.preview.Body = JSON.parse(sourcePreview?.preview?.Body);
        }
        return sourcePreview?.preview?.Body?.data?.length
          ? imageFromArrayBuffer(sourcePreview?.preview?.Body?.data)
          : '';
      }
      case SourceType.SLATE_INPUT: {
        setHasLoaded(true);
        return sourcePreview?.preview?.url;
      }
      case SourceType.SRT:
      case SourceType.RTMP:
      case SourceType.ZIXI:
        if (sourcePreview?.preview?.Body?.length) {
          sourcePreview.preview.Body = JSON.parse(sourcePreview?.preview?.Body);
        }
        return sourcePreview?.preview?.Body as string;
      default:
        return '';
    }
  }, [sourcePreview]);

  const handlePreviewUpdates = (
    parsedData: WebSocketResponseByType<WebSocketResponseTypes.DEVICE_PREVIEW_UPDATE>
  ) => {
    const [devicePreview, source] = parsedData.updatedDevicePreview;
    if (source.id === id) {
      setSourcePreview({ type: source.type, preview: devicePreview });
    }
  };

  const renderPreview = () => {
    switch (source.type) {
      case SourceType.ELEMENTAL_LINK:
        return (
          <Box
            borderRadius="5px"
            width="100%"
            height="100%"
            sx={{
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundImage: previewSrc ? `url(${previewSrc})` : undefined,
              backgroundColor: previewSrc ? undefined : 'text.secondary',
              backgroundPosition: 'center',
            }}
          />
        );
      case SourceType.SLATE_INPUT:
        return (
          <>
            <ReactHlsPlayer
              style={{ borderRadius: '5px' }}
              src={`${previewSrc}.m3u8`}
              playerRef={playerRef}
              width="100%"
              height="100%"
              controls
            />
            {!hasLoaded && (
              <Center
                width="100%"
                height="100%"
                borderRadius={1}
                bgcolor="grey.100"
                color="grey.600"
                position="absolute"
                top="0"
                textAlign="center"
              >
                <MovieRounded fontSize="large" sx={{ mb: 1 }} />
                <Typography variant="body2">Loading...</Typography>
              </Center>
            )}
          </>
        );
      case SourceType.SRT:
      case SourceType.RTMP:
      case SourceType.ZIXI:
        return <img src={previewSrc} alt="preview" />;
      default:
        return <></>;
    }
  };
  const { currentUserGroup } = useContext(GroupInfoContext);
  const {
    refetch: refetchResources,
    isFetching: isResourcesFetching,
    data: resourceDetails = [],
  } = useQuery(['Resource', id], () => ResourceService.getSourceResourcesByID(id), {
    enabled: true,
  });

  const { data: Slates } = useQuery(['Slates'], () =>
    SourceService.listSlates(currentUserGroup?.id)
  );

  const getSourceUrlFromZenmasterSource = () => {
    const zenmasterSource = resourceDetails.find(
      (resource) => resource.type === AWSResourceType.ZENMASTER_SOURCE
    );
    if (zenmasterSource) {
      const details = zenmasterSource.details as {
        type: string;
        target_host: string;
        listening_port: number;
        stream_id: string;
        remote_host: string;
        remote_port: string;
      };
      if (details.target_host) {
        const url = details.listening_port
          ? source?.broadcasterIp
            ? `${source?.broadcasterIp}:${details.listening_port}`
            : null
          : details?.remote_host
          ? `${details.remote_host}:${details.remote_port}`
          : null;
        switch (source.type) {
          case SourceType.SRT:
            return `srt://${url}`;
          case SourceType.RTMP:
            return source?.broadcasterIp
              ? `rtmp://${source.broadcasterIp}/live/${details?.stream_id}`
              : null;
          case SourceType.ZIXI:
            const zixiUrl =
              source?.specifications?.mode == 'Pull'
                ? `zixi://${url}`
                : `${details.target_host ? `zixi://${details.target_host}:2088` : null}`;
            return zixiUrl;
        }
      }
    }
    return null;
  };

  const renderName = () => {
    switch (source.type) {
      case SourceType.SLATE_INPUT:
        const slate = Slates?.filter((item) => item.id === source?.specifications?.slateId)[0];
        return slate?.name;
      case SourceType.ELEMENTAL_LINK:
        return source?.specifications?.deviceId;
      case SourceType.SRT:
      case SourceType.ZIXI:
      case SourceType.RTMP:
        return getSourceUrlFromZenmasterSource();
      default:
        return null;
    }
  };
  useEffect(() => {
    socketConnection.on('message', (data) => {
      try {
        const parsedData: WebSocketResponse = data && JSON.parse(JSON.stringify(data));
        if (parsedData?.type === WebSocketResponseTypes.DEVICE_PREVIEW_UPDATE) {
          handlePreviewUpdates(parsedData);
        }
        if (parsedData?.type === WebSocketResponseTypes.EVENT_STREAM_UPDATES) {
          const { status, isQueued, hasFailed } = parsedData.details.stream;
          if (
            (status === EventStreamStatus.CREATED && !isQueued && !hasFailed) ||
            (status === EventStreamStatus.DESTROYED && !isQueued && !hasFailed)
          ) {
            refetchResources();
          }
        }
      } catch (error) {}
    });

    return () => {
      socketConnection.off('message');
    };
  }, []);
  return (
    <>
      <Box
        p="2px"
        border="1px solid"
        bgcolor="white"
        borderColor={statusColor}
        borderRadius="5px 5px 0px 0px"
        className={UserTourClassSelector.SHOW_INPUTS}
        sx={{
          width: GRAPH_NODE_DIMENSIONS[type].width,
          height: GRAPH_NODE_DIMENSIONS[type].height,
          boxShadow: `0px 0px 4px ${statusColor + '9d'}`,
          cursor: 'pointer',
        }}
        position="relative"
        onClick={() => {
          setViewPopUpOpen(true);
          refetchResources();
        }}
      >
        {previewSrc ? renderPreview() : <NodePreviewPlaceholder source={source} />}
        <NodeDetails source={source} isFloating={Boolean(previewSrc)} />
      </Box>
      {Slates && renderName() && Slates.length !== 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'grey.100',
            padding: '8px',
            width: '100%',
            position: 'absolute',
            bottom: '-55px',
            borderRadius: '0px 0px 5px 5px',
          }}
        >
          <Typography
            sx={{
              width: '80%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <b>{renderName()}</b>
          </Typography>
          <CopyToClipboard text={renderName()} onCopy={() => setCopied(true)}>
            <IconButton>
              {copied ? <LibraryAddCheck color="success" /> : <ContentCopy />}
            </IconButton>
          </CopyToClipboard>
        </Box>
      ) : null}

      <PopUp
        isPopUpOpen={viewPopUpOpen}
        buttons={viewButtonAttr}
        close={viewButtonAttr[0].handler}
        title="Resource Details"
        fullWidth={true}
        maxWidth="md"
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ cursor: 'pointer' }}
          onClick={() => refetchResources()}
        >
          <RefreshIcon color="primary" />
        </Stack>
        {isResourcesFetching ? (
          <Center minHeight="200px">
            <CircularProgress />
          </Center>
        ) : resourceDetails.length !== 0 ? (
          resourceDetails.map((resource) => (
            <ResourceDetails
              key={resource.id}
              resource={resource}
              broadcasterData={{
                broadcasterName: source?.broadcasterName,
                broadcasterIp: source?.broadcasterIp,
              }}
            />
          ))
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <Typography variant="h6" sx={{ color: 'grey.600' }}>
              No Resources found!
            </Typography>
          </Box>
        )}
      </PopUp>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={false}
        style={{
          background: statusColor,
          width: '14px',
          height: '14px',
          borderWidth: '2px',
          right: '-6px',
        }}
      />
    </>
  );
};
