import { OutputType } from 'types';

export type RTMPOutputSpecification = {
  url: string;
  streamId: string;
  tag: string;
};

export type SRTOutputSpecification = {
  host: string;
  port: number;
  tag: string;
};

export type HLSOutputSpecification = {
  tag: 'NHL.com' | string;
};

export type OutputSpecification =
  | RTMPOutputSpecification
  | SRTOutputSpecification
  | HLSOutputSpecification;

export type OutputConfigType = {
  isMultiple: boolean;
};

// Flip the isMultiple to true for the output types that can have multiple outputs
export const OutputConfigDetail: Record<
  Exclude<OutputType, OutputType.COMMONCHANNEL>,
  OutputConfigType
> = {
  [OutputType.RTMP]: {
    isMultiple: true,
  },
  [OutputType.SRT]: {
    isMultiple: true,
  },
  [OutputType.ZIXI]: {
    isMultiple: true,
  },
  [OutputType.HLS]: {
    isMultiple: false,
  },
};

// use specific number to limit the output count, since the output count is not limited we use 99999999999
export const OutputsCount = Number.MAX_SAFE_INTEGER;
