import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import ProfileAvatar from 'boring-avatars';

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

import { avatarColors } from 'constants/MiscConstants';
import { ROUTE_KEY, ROUTE_PATH } from 'constants/RouteConstants';
import logo from 'assets/nhlflat.svg';

import { useViewAccess } from 'hooks/useViewAccess';

import { buildStyles } from 'utils/helpers';

import { AdminGroupNames, NHLLogo } from '../../constants/TeamConstants';
import { ActiveMenuContext } from '../../contexts/ActiveMenuContext';
import { CurrentUserInfoContext } from '../../contexts/CurrentUserInfoContext';
import { useGroups } from '../../contexts/GroupInfoContext';

const styles = buildStyles({
  active: (theme: Theme) => ({
    boxShadow: `0px 4px 0px ${theme.palette.highlight.underline} inset`,
    borderRadius: '0px',
    fontSize: '16px',
    fontWeight: 'bolder',
    color: 'common.white',
  }),
  inactive: {
    fontWeight: 400,
    borderRadius: '0px',
    fontSize: '14px',
    '&$link': {
      color: 'text.secondary',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    minWidth: '0px',
    padding: '0px 8px',
    '&:hover': {
      color: 'inherit',
    },
  },
  menuItemLink: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  title: {
    color: 'grey.300',
    width: '140px',
    fontSize: '20px',
    fontWeight: 'bolder',
  },
  sectionDesktop: (theme: Theme) => ({
    display: 'none',
    marginLeft: 'auto',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      columnGap: '20px',
    },
  }),
  submit: {
    backgroundColor: 'primary.main',
    color: 'common.white',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: 'primary.main',
      color: 'common.white',
    },
  },
  fullWidth: (theme: Theme) => ({
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      padding: '0 80px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      padding: '0 50px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 40px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  }),
  logoCommonStyles: {
    height: '30px',
    width: '30px',
  },
});

function MenuBar(): JSX.Element {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { activeMenu } = useContext(ActiveMenuContext);
  const { userInfo } = useContext(CurrentUserInfoContext);
  const { currentUserGroup, isUserAdminGroup } = useGroups();
  const {
    canAccessDashboard,
    canAccessControlCenter,
    canAccessManageTeams,
    canAccessActivityLogs,
    canAccessAnalytics,
    canAccessSlates,
    canAccessDevices,
  } = useViewAccess();
  const mobileMenuId = 'menu-mobile';

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleRegionSwitchMenuOpen = () => {
    handleMobileMenuClose();
  };

  const handleClickLocationMenu = () => {
    handleMobileMenuClose();
  };

  const handleSignOut = () => {
    Auth.signOut();
    localStorage.removeItem('notificationRead');
  };

  const getUserGroupLogo = () => {
    if (isUserAdminGroup()) {
      switch (userInfo?.group) {
        case AdminGroupNames.PRESIDIO:
          return (
            <Box sx={{ padding: '8px 8px' }}>
              <Avatar
                sx={{
                  ...styles.logoCommonStyles,
                  backgroundColor: 'common.white',
                  color: 'primary.main',
                }}
              >
                P
              </Avatar>
            </Box>
          );
        case AdminGroupNames.NHL:
        default:
          return null;
      }
    }

    return (
      <Box ml={1}>
        {currentUserGroup?.id === NHLLogo.NHLOriginalProductions ? (
          <img height="30px" src={logo} alt="team logo" style={{ margin: '4px 0px 0px 0px' }} />
        ) : (
          currentUserGroup?.logo && (
            <img
              height="30px"
              src={currentUserGroup?.logo}
              alt="team logo"
              style={{ margin: '4px 0px 0px 0px' }}
            />
          )
        )}
      </Box>
    );
  };

  const renderMenu = () => (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      onClick={() => setMobileMoreAnchorEl(null)}
    >
      {canAccessDashboard && (
        <MenuItem component={Link} sx={styles.menuItemLink} to={ROUTE_PATH.DASHBOARD}>
          Dashboard
        </MenuItem>
      )}
      {canAccessControlCenter && (
        <MenuItem component={Link} sx={styles.menuItemLink} to={ROUTE_PATH.CONTROL_CENTER}>
          Control Center
        </MenuItem>
      )}
      {canAccessSlates && (
        <MenuItem component={Link} sx={styles.menuItemLink} to={ROUTE_PATH.SLATES}>
          Manage Slates
        </MenuItem>
      )}
      {canAccessManageTeams && (
        <MenuItem component={Link} sx={styles.menuItemLink} to={ROUTE_PATH.MANAGE_TEAMS}>
          Manage Team
        </MenuItem>
      )}
      {canAccessActivityLogs && (
        <MenuItem component={Link} sx={styles.menuItemLink} to={ROUTE_PATH.ACTIVITY_LOG}>
          Activity Log
        </MenuItem>
      )}
      {canAccessAnalytics && (
        <MenuItem component={Link} sx={styles.menuItemLink} to={ROUTE_PATH.ANALYTICS}>
          Analytics
        </MenuItem>
      )}
      {canAccessDevices && (
        <MenuItem component={Link} sx={styles.menuItemLink} to={ROUTE_PATH.DEVICES}>
          Devices
        </MenuItem>
      )}
      <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
    </Menu>
  );
  // TODO: rap button with check previlege func eg:isUserAdminAndOperator() and add to(router path) in button tag
  return (
    <>
      <AppBar sx={{ backgroundColor: 'text.primary', borderRadius: '0px' }}>
        <Toolbar sx={styles.fullWidth}>
          <Box display="flex">
            <Button disableRipple component={Link} to={ROUTE_PATH.DASHBOARD} sx={styles.link}>
              <Box>
                <img height="30px" src={logo} alt="logo" style={{ margin: '4px 0px 0px 0px' }} />
              </Box>
            </Button>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ backgroundColor: 'text.secondary', height: '30px', margin: 'auto' }}
            />
            {getUserGroupLogo()}
          </Box>
          <Button disableRipple component={Link} sx={styles.link} to={ROUTE_PATH.DASHBOARD}>
            <Typography variant="h5" sx={styles.title} display={{ xs: 'none', sm: 'block' }}>
              Clubstreams
            </Typography>
          </Button>
          <Box sx={styles.sectionDesktop}>
            {canAccessDashboard && (
              <Button
                component={Link}
                sx={[
                  activeMenu === ROUTE_KEY.DASHBOARD ? styles.active : styles.inactive,
                  styles.link,
                ]}
                to={ROUTE_PATH.DASHBOARD}
              >
                Dashboard
              </Button>
            )}
            {canAccessControlCenter && (
              <Button
                component={Link}
                sx={[
                  activeMenu === ROUTE_KEY.CONTROL_CENTER ? styles.active : styles.inactive,
                  styles.link,
                ]}
                to={ROUTE_PATH.CONTROL_CENTER}
              >
                Control Center
              </Button>
            )}
            {canAccessSlates && (
              <Button
                component={Link}
                sx={[
                  activeMenu === ROUTE_KEY.SLATES ? styles.active : styles.inactive,
                  styles.link,
                ]}
                to={ROUTE_PATH.SLATES}
              >
                Manage Slates
              </Button>
            )}
            {canAccessManageTeams && (
              <Button
                component={Link}
                sx={[
                  activeMenu === ROUTE_KEY.MANAGE_TEAMS ? styles.active : styles.inactive,
                  styles.link,
                ]}
                to={ROUTE_PATH.MANAGE_TEAMS}
              >
                Manage Team
              </Button>
            )}
            {canAccessActivityLogs && (
              <Button
                onClick={handleClickLocationMenu}
                component={Link}
                sx={[
                  activeMenu === ROUTE_KEY.ACTIVITY_LOG ? styles.active : styles.inactive,
                  styles.link,
                ]}
                to={ROUTE_PATH.ACTIVITY_LOG}
              >
                Activity Log
              </Button>
            )}
            {canAccessAnalytics && (
              <Button
                onClick={handleClickLocationMenu}
                component={Link}
                sx={[
                  activeMenu === ROUTE_KEY.ANALYTICS ? styles.active : styles.inactive,
                  styles.link,
                ]}
                to={ROUTE_PATH.ANALYTICS}
              >
                Analytics
              </Button>
            )}
            {canAccessDevices && (
              <Button
                onClick={handleClickLocationMenu}
                component={Link}
                sx={[
                  activeMenu === ROUTE_KEY.DEVICES ? styles.active : styles.inactive,
                  styles.link,
                ]}
                to={ROUTE_PATH.DEVICES}
              >
                Devices
              </Button>
            )}
            <Button
              aria-label="profile"
              sx={{ color: 'text.secondary', fontWeight: 'bolder' }}
              onClick={handleRegionSwitchMenuOpen}
              endIcon={
                <ProfileAvatar
                  name={userInfo.email}
                  variant="beam"
                  colors={avatarColors}
                  size="30px"
                ></ProfileAvatar>
              }
            >
              {userInfo.firstName}
            </Button>
            <Box display={{ xs: 'none', sm: 'inline-flex' }}>
              <Button
                size="small"
                type="submit"
                variant="contained"
                onClick={handleSignOut}
                sx={[styles.submit]}
              >
                SIGN OUT
              </Button>
            </Box>
          </Box>
          <Box ml="auto" display={{ lg: 'none' }}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu()}
    </>
  );
}

export default React.memo(MenuBar);
