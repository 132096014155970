import { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';

import { GroupInfo, PopUpButtonProps } from 'types';

import { PopUp } from '.';

interface PublishPopUpProps {
  isPublishPopUpOpen: boolean;
  close: () => void;
  eventGroup: GroupInfo;
  selectedEvent: any;
  buttons: PopUpButtonProps[];
  selectedValue: string;
  setSelectedValue: (value: string) => void;
}

const PublishPopUp = ({
  isPublishPopUpOpen,
  close,
  eventGroup,
  buttons,
  selectedEvent,
  selectedValue,
  setSelectedValue,
}: PublishPopUpProps) => {
  const queryClient = useQueryClient();

  const options = useMemo(() => {
    return eventGroup?.videoIds && !_.isEmpty(eventGroup.videoIds)
      ? Object.keys(eventGroup.videoIds).filter(
          (key) => eventGroup.videoIds[key] != null && eventGroup.videoIds[key] !== ''
        )
      : [];
  }, [eventGroup]);

  const filteredElementalLink = useMemo(() => {
    return selectedEvent?.sources
      ? _.filter(selectedEvent.sources, { type: 'ELEMENTAL_LINK' })
      : [];
  }, [selectedEvent]);

  const onInputChange = (e, value) => {
    if (value) setSelectedValue(value);
  };

  useEffect(() => {
    queryClient.setQueryData(['ResourceId'], []);
  }, [selectedEvent]);

  useEffect(() => {
    if (_.isEmpty(options)) {
      setSelectedValue('');
    } else if (options.length === 1) {
      setSelectedValue(options[0]);
    } else if (options.length > 1 && !_.isEmpty(filteredElementalLink)) {
      const deviceId = filteredElementalLink[0].specifications.deviceId;
      const formattedDeviceId = deviceId?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
      const selectedOption = _.find(
        options,
        (option) => option.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() === formattedDeviceId
      );
      setSelectedValue(selectedOption || options[0]);
    } else {
      setSelectedValue(options[0]);
    }
  }, [selectedEvent]);

  return (
    <PopUp
      buttons={buttons}
      isPopUpOpen={isPublishPopUpOpen}
      fullWidth
      close={close}
      title="Publish"
      disableCTA={_.isEmpty(options)}
    >
      <Box textAlign="center">
        {options.length > 0 ? (
          <Stack direction="row" alignItems="center" justifyContent="center" flexWrap="wrap">
            <Typography>Publish to</Typography>
            {options.length > 1 ? (
              <Box mx={1}>
                <Autocomplete
                  size="small"
                  options={options || []}
                  value={selectedValue}
                  onChange={onInputChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Name"
                      sx={{
                        '& .MuiInputBase-root': {
                          width: '220px',
                        },
                      }}
                    />
                  )}
                />
              </Box>
            ) : (
              <Typography color="primary" mx={1} fontWeight={700}>
                {selectedValue}
              </Typography>
            )}

            <Typography>asset on Brightcove?</Typography>
          </Stack>
        ) : (
          <Typography fontWeight={700} sx={{ color: 'grey.600' }}>
            No assets found.
          </Typography>
        )}
      </Box>
    </PopUp>
  );
};

export default PublishPopUp;
