import { useContext, useMemo } from 'react';

import { CurrentUserInfoContext } from 'contexts/CurrentUserInfoContext';

import { validateUser } from 'utils/helpers';

import {
  ActivityLogAllowedRoles,
  AnalyticsAllowedRoles,
  ControlCenterAllowedRoles,
  DashboardAllowedRoles,
  DeviceAllowedRoles,
  ManageTeamsAllowedRoles,
  SlatesAllowedRoles,
} from '../constants/AccessConstants';
interface ViewAccessReturnType {
  canAccessDashboard: boolean;
  canAccessControlCenter: boolean;
  canAccessManageTeams: boolean;
  canAccessActivityLogs: boolean;
  canAccessAnalytics: boolean;
  canAccessSlates: boolean;
  canAccessDevices: boolean;
}

export function useViewAccess(): ViewAccessReturnType {
  const { userInfo } = useContext(CurrentUserInfoContext);
  const accessInfo = useMemo(() => {
    return {
      canAccessDashboard: validateUser(userInfo.role, DashboardAllowedRoles),
      canAccessControlCenter: validateUser(userInfo.role, ControlCenterAllowedRoles),
      canAccessManageTeams: validateUser(userInfo.role, ManageTeamsAllowedRoles),
      canAccessActivityLogs: validateUser(userInfo.role, ActivityLogAllowedRoles),
      canAccessAnalytics: validateUser(userInfo.role, AnalyticsAllowedRoles),
      canAccessSlates: validateUser(userInfo.role, SlatesAllowedRoles),
      canAccessDevices: validateUser(userInfo.role, DeviceAllowedRoles),
    };
  }, [userInfo]);
  return accessInfo;
}
